import 'bootstrap/dist/css/bootstrap.min.css'
import ScratchCard from 'components/scratch-card'
import React from 'react'
import './App.scss'
import './wdyr'

const App = () => (
  <ScratchCard />
)

export default App
