import React, { useEffect, useRef } from 'react'
import { SCRATCH_TYPE, ScratchCard } from 'scratchcard-js'

const ScratchCardComponent = () => {
  const ref = useRef()

  const init = () => {
    const sc = new ScratchCard('#js--sc--container', {
      scratchType: SCRATCH_TYPE.CIRCLE,
      containerWidth: 300,
      containerHeight: 300,
      brushSrc: '',
      imageForwardSrc: '/images/scratchcard.jpg',
      imageBackgroundSrc: '',
      htmlBackground: `<div class='inner_html'><p>Html content</p></div>`,
      clearZoneRadius: 30,
      nPoints: 50,
      pointSize: 4,
      callback: function () {
        alert('Now the window will reload !')
        window.location.reload()
      }
    })
    sc.init()
      .then(() => {
        sc.canvas.addEventListener('scratch.move', () => {
          let percent = sc.getPercent().toFixed(2)
          console.log(percent)
        })
      })
      .catch(error => {
        // image not loaded
        alert(error.message)
      })
  }
  useEffect(() => {
    init()
  }, [])

  return (
    <div className="sc__wrapper">
      <div id="js--sc--container" className="sc__container" />
      <div className="sc__infos" />
    </div>
  )
}
export default ScratchCardComponent
